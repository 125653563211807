<template>
  <div class="top-up">
    <div class="home-title">
        {{$t('common.all')}}
        <div class="home-subtitle">{{$t('common.title_10')}}</div>
    </div>
    <div class="container">
      <a-spin size="large" :spinning="loading">
      <van-tabs animated swipeable  v-model="active" color="#2564F6" @change="(id)=>{getProducts(id)}">
          <van-tab :title="tab.cate_name" :name="tab.id"  v-for="(tab,i) in Categorys" :key="i">
            <a-row v-if="Products.length" class="product-list" type="flex" justify="start" :gutter="[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]">
              <a-col class="wrapper-item" :xs="12" :md="8" :lg="8" :xl="6" v-for="(item,index) in Products" :key="index">
                <div class="inner-item" @click="$router.push({path: '/details', query: {id:item.id}})">
                  <van-image show-loading show-error fit="contain" lazy-load :src="item.image">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="product-grid-info">
                    <div class="product-name">
                      {{item.store_name}}
                    </div>
                    <div class="flex-between product-price">
                      <span>
                         HK$ {{item.price}}
                      </span>
                      <van-icon name="arrow" color="#999"/>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
            <van-empty v-show="isEmpty" description="暂无套餐"/>
          </van-tab>
      </van-tabs>
      </a-spin>
    </div>
  </div>
</template>
<script>
import { Category, Products } from '@/api/api.js'
export default {
  data() {
    return {
      loading:false,
      isEmpty:false,
      active: 0,
      Categorys:[],
      Products:[]
    }
  },
  created() {
   this.init()
  },
  methods: {
    async init(){
      const { data } = await Category({pid:1,limit:10})
      this.Categorys = data
      this.getProducts(data[0].id)
    },
    async getProducts(id){
      this.loading = true
      const { data } = await Products({sid:id, cid:0})
      this.loading = false
      this.Products = data.list
      this.isEmpty = data.list.length ? false : true 
    },
    goDetails(id){
      this.$router.push({ name: 'Details', query: { id: id ,type:1}});
    },
  },
}
</script>
<style lang="scss" scoped>
.top-up{
  user-select: none;
}
</style>