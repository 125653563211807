import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import Message from 'ant-design-vue/es/message'
// import { Message} from 'ant-design-vue';
// Message.warning(i18n.t('common.loginTips'))

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: '/api',
    // baseURL: process.env.VUE_APP_API_HOST,
    timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const token = localStorage.getItem('smart_token')
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = 'Bearer '+token
    }
    // console.log(store)
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    if (response.data.status !== 200) {
        Message.warning(response.data.msg)
    }
    if (response.data.status === 110002 || response.data.status === 110003 ||response.data.status === 110004) {
        localStorage.removeItem('smart_token')
        store.commit('LoginShow', true)
        store.commit('Login', false)
    }
    return response.data
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}