<template>
  <div id="app">
    <div id="nav" class="container">
      <div class="flex-between container">
      <img class="logo" src="./assets/top_logo.png" @click="select(xsLink[0])">
      <div class="navbar">
        <div class="nav-list">
          <!-- <router-link class="home-xs" to="/">{{ $t("route.home") }}</router-link> -->
          <router-link class="home-xs" to="/">{{ $t("route.recharge") }}</router-link>
          <router-link class="home-xs" to="/enquiry">使用查詢</router-link>
          <van-popover v-if="$store.state.login" v-model="lgPopover" trigger="click" :actions="lgLink" :offset="[-22,20]" @select="select">
            <template #reference>
              <a class="mine-lg dropdown-toggle">{{ $t("route.mine") }}</a>
            </template>
          </van-popover>
          <a class="mine-lg" v-else @click="$store.commit('LoginShow', true)">{{$t("route.login")}}</a>
           <van-popover v-if="$store.state.login" placement="bottom-end" v-model="xsPopover" trigger="click" :actions="xsLink"  @select="select">
            <template #reference>
              <div class=" flex">
              <a class="mine-xs"><van-icon name="wap-nav flex" color="#333" size="2.2rem"/></a>
              </div>
            </template>
          </van-popover>
          <a class="mine-xs colorBtn" v-else  @click="$store.commit('LoginShow', true)"><i class="iconfont icon-mine"></i></a>
        </div>
        <!-- <div class="lang">
          <span @click="langClick('zh-cn')" :class="{langActive:$i18n.locale=='zh-cn'}">简</span>|
          <span @click="langClick('zh-tw')" :class="{langActive:$i18n.locale=='zh-tw'}">繁</span>|
          <span @click="langClick('en')" :class="{langActive:$i18n.locale=='en'}">EN</span>
        </div> -->
        <van-search class="search-lg" v-model="keyword" clearable shape="round" @search="searchHandle" :placeholder="$t('route.search')" />
      </div>
      </div>
      <van-search class="search-xs" v-model="keyword" clearable shape="round" @search="searchHandle" :placeholder="$t('route.search')" />
    </div>
    <router-view style="flex:1;"/>
    <myFooter></myFooter>
    <login></login>
  </div>
</template>
<script>
import { Logout } from '@/api/api.js'
import login from './components/login.vue';
import myFooter from './components/myFooter.vue';
export default {
  components: { login,myFooter },
  data() {
    return {
      keyword:'',
      lgPopover: false,
      xsPopover: false,
      lgLink: [],
      xsLink: [],
    }
  },
  methods:{
    searchHandle(value){
      this.$router.push({ name: 'Search', query: { keyword:value}});
    },
    select(name){
      if(name.url==this.$route.name){
        this.show = true 
        return
      }
      if(name.url=='Logout'){
        this.$dialog.confirm({
        title: this.$t('login.logout_title'),
        message: this.$t('login.logout_text'),
        confirmButtonText:this.$t('login.logout_ok'),
        cancelButtonText:this.$t('common.cancel'),
        }).then(() => {
          this.logoutHandler()
        }).catch(() => {});
        return
      }
      this.show = true 
      this.$router.push({ name: name.url});
    },
    async logoutHandler(){
      const result = await Logout()
      localStorage.removeItem('smart_token')
      this.$store.commit('Login', false)
      this.$router.replace({ name: 'TopUp'});
    },
    // langClick(lang){
    //   if (this.$route.query.locale) {
    //     let query = JSON.parse(JSON.stringify(this.$route.query))
    //     delete query.locale
    //     this.$router.push({ query: query });
    //   }
    //   if(lang == 'zh-cn'){
    //     localStorage.setItem('sim_lang', 'zh-cn')
    //     this.$i18n.locale = localStorage.getItem('sim_lang')
    //   } else if (lang == 'en') {
    //     localStorage.setItem('sim_lang', 'en')
    //     this.$i18n.locale = localStorage.getItem('sim_lang')
    //   }else if (lang == 'zh-tw') {
    //     localStorage.setItem('sim_lang', 'zh-tw')
    //     this.$i18n.locale = localStorage.getItem('sim_lang')
    //   }
    //   location.reload()
    // }
  },
  mounted(){
    if(localStorage.getItem('smart_token')){
      this.$store.commit('Login', true)
    }else{
      this.$store.commit('Login', false)
    }
    // if (this.$route.query.locale) {
    //   switch (this.$route.query.locale) {
    //     case 'zh-cn':
    //       localStorage.setItem('sim_lang', this.$route.query.locale)
    //       this.$i18n.locale = this.$route.query.locale
    //       break;
    //     case 'zh-tw':
    //       localStorage.setItem('sim_lang', 'zh-cn')
    //       this.$i18n.locale = 'zh-cn'
    //       break;
    //     case 'en':
    //       localStorage.setItem('sim_lang', this.$route.query.locale)
    //       this.$i18n.locale = this.$route.query.locale
    //       break;
    //   }
    // }else if(localStorage.getItem('sim_lang')){
    //   this.$i18n.locale = localStorage.getItem('sim_lang').toLowerCase()
    // }else{
    //   localStorage.setItem('sim_lang', this.$i18n.locale)
    // }
    this.xsLink=[
        // { text: this.$t("route.home") ,url:'Home' },
        { text: this.$t("route.recharge") ,url:'TopUp' },
        { text: this.$t('route.user') ,url:'User' },
        // { text: this.$t('route.sim_order') ,url:'SimOrder'},
        { text: '全部订单' ,url:'Orders'},
        { text: '使用查询' ,url:'Enquiry'},
        // { text: this.$t('route.address') ,url:'Addess'},
        // { text: this.$t('route.guide') ,url:'Guide'},
        { text: this.$t("route.logout"),url:'Logout' }
    ]
    this.lgLink=[
        { text: this.$t('route.user') ,url:'User' },
        // { text: this.$t('route.sim_order') ,url:'SimOrder'},
        { text: '全部订单' ,url:'Orders'},
        // { text: this.$t('route.address') ,url:'Addess'},
        // { text: this.$t('route.guide') ,url:'Guide'},
        { text: this.$t("route.logout"),url:'Logout' }
    ]
  }
}
</script>
<style lang="scss">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  #nav {
    user-select: none;
    font-size: 1.4rem;
    height: 8rem;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #F2F3F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      width: 12rem;
      height: auto;
      cursor: pointer;
    }
    .navbar{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .search-lg{
      width: 20.6rem;
      padding: 0;
      border-radius: 18px;
    }
    .search-xs{
      display: none;
    }
    .nav-list{
      .mine-lg{
        display:inline-block;          
      }
      .mine-xs{
        display: none;          
      }
    }
    .lang{
      user-select: none;
      span{
        padding: 0 1rem;
        cursor: pointer;
        color: rgba(51, 51, 51, .6);
      }
      .langActive{
        color: #333;
        font-weight: 600;
      }
       margin-right: 4rem;
    }
    a {
      color: rgba(51, 51, 51, .6);
      position: relative;
      margin-right: 4rem;
      text-decoration:none;
      cursor: pointer;
      &.router-link-exact-active {
        color: #333;
          &::after{
          position: absolute;
          bottom: -1rem;
          left: 50%;
          transform: translate(-50%,0);
          content: '';
          width: 5.6rem;
          height: .4rem;
          background-color: $color;
        }
      }
    }
  }
  
}
@media(max-width:992px){
  #app {
    #nav {
      font-size: 1.4rem;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 1.2rem 0;
      .navbar{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        align-items: center;
      }
      .search-lg{
        display: none;
      }
      .search-xs{
        display: block;
        width: 90%;
        font-size: 1.2rem;
        padding: 0;
        background-color: #fff;
        margin-top: 1rem;
      }
      .nav-list{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .home-xs{
          display: none;
        }
        .mine-lg{
          display: none;          
        }
        .mine-xs{
          display: inline-block;
          padding-right: 1.6rem;
        }
      }
      .lang{
        padding-left: 1.6rem;
        padding-right: 1rem;
        margin-right: 0;
        span{
          padding: 0 .5rem;
        }
      }
      a {
        margin-right: 0;
        font-size: 1.7rem;
        &.router-link-exact-active {
          &::after{
            display: none;
          }
        }
      }
    }
  }
}
</style>
