import Vue from 'vue'
import VueRouter from 'vue-router'
import Topup from '../views/Topup.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TopUp',
    component: Topup,
    meta: {
      title_cn: '充值',
      title_en: 'Top-up',
      title_tw: '充值',
      show:true
    },
  },
  {
    path: '/enquiry',
    name: 'Enquiry',
    meta: {
      title_cn: '使用查询',
      title_en: 'Dataplan Enquiry',
      title_tw: '漫遊卡',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Enquiry.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title_cn: '漫游卡',
      title_en: 'SIM Card',
      title_tw: '漫遊卡',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: {
      login: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Mine.vue'),
    children: [
      {
        path: '/mine/user',
        name: 'User',
        meta: {
          login: true,
          title_cn: '个人信息',
          title_en: 'Account',
          title_tw: '個人信息',
          show:true
        },
        component: () => import('../views/User.vue'),
      },
      {
        path: '/mine/orders',
        name: 'Orders',
        meta: {
          login: true,
          title_cn: '充值订单',
          title_en: 'Top-up orders',
          title_tw: '充值訂單',
          show:true
        },
        component: () => import('../views/Orders.vue'),
      },
      {
        path: '/mine/guide',
        name: 'Guide',
        meta: {
          login: true,
          title_cn: '使用帮助',
          title_en: 'User Manual',
          title_tw: '使用幫助',
          show:true
        },
        component: () => import('../views/Guide.vue'),
      },
      {
        path: '/mine/addess',
        name: 'Addess',
        meta: {
          login: true,
          title_cn: '收货地址',
          title_en: 'Address',
          title_tw: '收貨地址',
          show:true
        },
        component: () => import('../views/Addess.vue'),
      },
    ]
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      title_cn: '商品详情',
      title_en: 'Product details',
      title_tw: '商品詳情',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path: '/cashier',
    name: 'Cashier',
    meta: {
      login: true,
      title_cn: '确认订单',
      title_en: 'Confirm',
      title_tw: '確認訂單',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Cashier.vue')
  },
  
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title_cn: '隐私政策',
      title_en: 'Privacy Policy',
      title_tw: '隱私政策',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/treaty',
    name: 'Treaty',
    meta: {
      title_cn: '用户协议',
      title_en: 'User Agreement',
      title_tw: '用戶協議',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Treaty.vue')
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
      title_cn: '下载万游宝 APP',
      title_en: 'Download TravelMate APP',
      title_tw: '下載萬遊寶 APP',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Download.vue')
  },
  {
    path: '*', // 匹配所有路由
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/** 解决跳转重复路由报错问题 */ 
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}
export default router
