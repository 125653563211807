import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css';
import Vant from 'vant';
import '@vant/touch-emulator';
import 'vant/lib/index.css';
import login from '@/components/login.vue'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import { Modal,Row,Col,Message,AutoComplete,FormModel,Divider,Spin,Input,Table,Card,Icon,Empty,Space,Pagination,Descriptions  } from 'ant-design-vue';

import '../src/static/css/global.css';/*引入公共样式*/
import '../src/static/font/iconfont.css';
import { Lazyload } from 'vant';
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.prototype.$ = $;
Vue.use(Modal);
Vue.use(FormModel);
Vue.use(Divider)
Vue.use(Input)
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Spin.name, Spin);
Vue.component(Table.name, Table);
Vue.component(Card.name, Card);
Vue.component(Icon.name, Icon);
Vue.component(Empty.name, Empty);
Vue.component(Space.name, Space);
Vue.component(Pagination.name, Pagination);
Vue.component(Descriptions .name, Descriptions );
Vue.component('a-auto-complete', AutoComplete)
Vue.use(Vant);
Vue.component("login", login);//全局自定义组件

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 构造i18n对象
import VueI18n from 'vue-i18n'
import enLocale from './lang/en'
import cnLocale from './lang/zh-cn'
import twLocale from './lang/zh-tw'
Vue.use(VueI18n)
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的cn、en属性
	locale: 'zh-tw',
	// 引入语言文件
	messages: {
	// 这里的属性名是任意的，您也可以把zh设置为cn等，只是后续切换语言时
	// 要标识这里的语言属性，如：this.$i18n.locale = zh|en|cn|xxx
	'zh-cn': cnLocale,
	'en': enLocale,
  'zh-tw':twLocale
	},
})
const app = new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(document.body.style.overflow == 'hidden'){
		document.body.style.overflow = '';
	}
	if(to.meta.login){
		if(localStorage.getItem('smart_token')){
		next()
		}else{
		app.$store.commit('LoginShow', true)
		app.$store.commit('Login', false)
		}
	}else{
		next()
	}
})
router.afterEach((to, form) => {
	switch (app.$i18n.locale) {
		case 'en':
			document.title = to.meta.title_en
			break;
		case 'zh-tw':
			document.title = to.meta.title_tw
			break;
		default:
			document.title = to.meta.title_cn
			break;
	}
})
export default app
