import request from '@/utils/request'

export function Category(parameter) {
  return request({
    url: 'category',
    method: 'get',
    params: parameter
  })
}
export function Products(parameter) {
  return request({
    url: 'pc/get_products',
    method: 'get',
    params: parameter
  })
}
export function ProductDetail(parameter) {
  return request({
    url: 'product/detail/' + parameter,
    method: 'get',
    // params: parameter
  })
}
export function CheckCardNumber(parameter) {
  return request({
    url: 'get_simcard/' + parameter,
    method: 'get',
    // params: parameter
  })
}
export function AddCart(parameter) {
  return request({
    url: 'cart/add',
    method: 'post',
    data: parameter
  })
}
export function ConfirmOrder(parameter) {
  return request({
    url: 'order/confirm',
    method: 'post',
    data: parameter
  })
}
export function CreateOrder(parameter,key) {
  return request({
    url: 'order/create/' + key,
    method: 'post',
    data: parameter
  })
}
export function OrderDetail(id) {
  return request({
    url: 'order/detail/' + id,
    method: 'get',
  })
}
export function Cashier(id) {
  return request({
    url: 'order/cashier/' + id,
    method: 'get',
  })
}
export function OrderPay(parameter) {
  return request({
    url: 'order/pay',
    method: 'post',
    data: parameter
  })
}
export function OrderList(parameter) {
  return request({
    url: 'pc/get_order_list',
    method: 'get',
    params: parameter
  })
}
export function CodeKey(parameter) {
  return request({
    url: 'verify_code',
    method: 'get',
    params: parameter
  })
}
export function SendCode(parameter) {
  return request({
    url: 'register/verify',
    method: 'post',
    data: parameter
  })
}
export function PasswordLogin(parameter) {
  return request({
    url: 'login',
    method: 'post',
    data: parameter
  })
}
export function CodeLogin(parameter) {
  return request({
    url: 'captcha/login',
    method: 'post',
    data: parameter
  })
}
export function Logout(parameter) {
  return request({
    url: 'logout',
    method: 'get',
    params: parameter
  })
}
export function UserInfo(parameter) {
  return request({
    url: 'userinfo',
    method: 'get',
    params: parameter
  })
}
export function ResetPassword(parameter) {
  return request({
    url: 'register/reset',
    method: 'post',
    data: parameter
  })
}
export function Enquiry(iccid) {
  return request({
    url: 'enquiry/' + iccid,
    method: 'get',
  })
}