<template>
  <div id="footer">
    <!-- <div class="container">
      <div class="header">
        <div class="left">
          <img class="logo" src="../assets/footer_logo.png" alt="" />
          <span class="icon">|</span>
          <span class="slogan">{{$t('common.slogan')}}</span>
        </div>
        <div class="name">{{$t('footer.name')}}</div>
      </div>
      <div class="cont">
        <div class="cont-lg row">
          <div class="col-5">
            <div class="item">
              <div class="title"><i class="iconfont icon-dw"></i>{{$t('footer.hk_name')}}</div>
              <span>{{$t('footer.hk_addess')}}</span>
              <span>+852-5196-1035</span>
            </div>
            <div class="item">
              <div class="title"><i class="iconfont icon-dw"></i>{{$t('footer.am_name')}}</div>
              <span>{{$t('footer.am_addess')}}</span>
              <span>+853-6611-6270</span>
            </div>
            <div class="item">
              <div class="title"><i class="iconfont icon-huaban"></i>{{$t('footer.kf_title')}}</div>
              <span>reservation@triproaming.com</span>
            </div>
          </div>
          <div class="col-4">
            <div class="item">
              <div class="title"><i class="iconfont icon-dw"></i>{{$t('footer.sz_name')}}</div>
              <span>{{$t('footer.sz_addess')}}</span>
              <span>+86-755-2267-3112</span>
            </div>
            <div class="item">
              <div class="title"><i class="iconfont icon-dw"></i>{{$t('footer.tw_name')}}</div>
              <span>{{$t('footer.tw_addess')}}</span>
              <span>+886-908951870</span>
            </div>
            <div class="item">
              <div class="title"><i class="iconfont icon-huaban"></i>{{$t('footer.work_title')}}</div>
              <span>agent@triproaming.com</span>
            </div>
          </div>
          <div class="col-3 right">
            <div class="right-item">
              <div class="title"><i class="iconfont icon-lianjie"></i>{{$t('footer.link_title')}}</div>
              <span><a href="http://www.triproaming.cn/" target="_blank"> {{$t('footer.link_yyb')}}</a></span>
              <span><a href="http://sim.triproaming.cn/">{{$t('footer.link_wyb')}}</a></span>
              <span><a href="http://esim.triproaming.com/" target="_blank">{{$t('footer.link_esim')}}</a></span>
            </div>
            <ul class="list flex-between">
                <li v-for="(item,index) in list" :key="index">
                    <van-popover v-model="item.show"  placement="top">
                    <van-grid square clickable :border="false">
                        <div class="scan-code">
                            <div>{{item.text}}</div>
                            <img style="width:9.6rem; height: auto;" :src="item.img" alt="">
                        </div>
                    </van-grid>
                    <template #reference>
                        <img :src="item.icon" class="icon" :class="{iconActive:item.show}" @mouseover="show(index)" @mouseleave="hide(index)" @click="jump(index)"> 
                    </template>
                    </van-popover>
                </li>
            </ul>
          </div>
        </div>
        <van-button plain round type="primary" class="download" to="/download">{{$t('footer.download')}}</van-button>
      </div>
    </div> -->
    <div class="contact-us">
      <div class="container">
        <a-row :gutter="[{ xs: 0, sm: 0, md: 0 }, { xs: 10, sm: 10, md:10, lg:0 }]">
          <a-col :xs="24" :sm="24" :md="24" :lg="11">
            <span class="title">請聯系客服獲取幫助</span>
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="13">
            <a-col :xs="24" :sm="8" :md="8" :lg="8">
                <a-space>
                <van-icon size="20" class="flex" :name="require('../assets/whatsapp.png')" />
                +852 5196 1035
                </a-space>
            </a-col>
            <a-col :xs="24" :sm="8" :md="8" :lg="8">
                <a-space>
                <van-icon size="20" class="flex" :name="require('../assets/mobile.png')" />
                line： @521yokau
              </a-space>
            </a-col>
            <a-col :xs="24" :sm="8" :md="8" :lg="8">
                <a-space>
                  <van-icon size="20" class="flex" :name="require('../assets/email.png')" />
                  reservation@triproaming.com
                </a-space>
            </a-col>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="copyright">
      <div class="container info">
        <span>Copyright © Triproaming International Holdings Ltd. All Rights Reserved.{{$t('footer.name')}}</span>
        <!-- <span><a href="https://beian.miit.gov.cn/" target="_blank">{{icpNumber}} &nbsp;&nbsp;</a>粤公安网备440011225858 &nbsp;&nbsp;增值电信业务经营许可证 粤B2-20210862</span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
          icpNumber:'',
          showPopover:1,
          list:[
              {text:this.$t('footer.scan_app'),show:false,icon:'http://esim.qn.triproaming.cn/icon_esim_a%402x.png',img:'http://esim.qn.triproaming.cn/icon_qrcode_esim%402x.png'},
              {text:this.$t('footer.scan_app'),show:false,icon:'http://esim.qn.triproaming.cn/icon_wyb_a%402x.png',img:'http://esim.qn.triproaming.cn/icon_qrcode_wyb%402x.png'},
              {text:this.$t('footer.scan_wechat'),show:false,icon:'http://esim.qn.triproaming.cn/icon_wechat_a%402x.png',img:'http://esim.qn.triproaming.cn/icon_qrcode_wechat%402x.png'},
              {show:false,icon:'http://esim.qn.triproaming.cn/icon_ins_a%402x.png'},
              {show:false,icon:'http://esim.qn.triproaming.cn/icon_fb_a%402x.png'},
          ]
        }
    },
    mounted(){
      const domain = window.location.hostname;
      if (/\.cn$/.test(domain)) {
        // 域名以.com结尾
        this.icpNumber = '粤ICP备17127815号-1'
      } else if (/\.com$/.test(domain)) {
        // 域名以.cn结尾
        this.icpNumber = '粤ICP备17127815号-2'
      } else {
        // 域名既不以.com结尾也不以.cn结尾
        this.icpNumber = '粤ICP备17127815号-1'
      }
    },
    methods:{
        jump(index){
          if(index==3){
            window.location.href = 'https://instagram.com/travelmate_triproaming?utm_medium=copy_link';
            return
          }
          if(index==4){
            window.location.href = 'https://www.facebook.com/travelmatetriproaming';
            return
          }
        },
        show(index){
          if(index==3){
            return
          }
          if(index==4){
            return
          }
            this.list[index].show = true
            this.list.map((val, idx) => {
					if (index != idx) this.list[idx].show = false;
			  })
        },
        hide(index){
            this.list[index].show = false
        }
    }
};
</script>
<style lang="scss" scoped>
#footer {
  margin-top: 8rem;
  color: #fff;
  background-color: $color;
  .contact-us{
    color: #999999;
    padding: 2rem 0;
    background-color: #1C2024;
    border-bottom: 1px solid #666;
    .title{
      font-size: 16px;
      font-weight: 600;
    }
  }
  .header {
    font-size: 1.8rem;
    padding: 3.3rem 0 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff;
    .logo{
      width: 12rem;
      height: 3.7rem;
    }
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon {
        margin: 0 1rem;
      }
    }
  }
  .cont {
    padding: 2rem 0 4rem 0;
    .cont-lg {
      .right{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .right-item{
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        span{
          margin-top: 1rem;
          text-indent: 2.2em;
          a{
            color: #fff;
            text-decoration: none;
          }
        }        
      }
      .title {
        font-weight: 600;
        font-size: 1.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .iconfont{
          margin-right: 1rem;
        }
      }
      .item {
        font-size: 1.2rem;
        margin-bottom: 3rem;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        span{
          word-wrap:break-word;
          margin-top: .5rem;
          padding-left: 2.7rem;
        }
        &:last-child{
            margin-bottom: 0;
        }
       
      }
      .list{
          li{
            .icon{
              width:2rem;
              height: auto;
              cursor: pointer;
           }
            .iconActive{
              opacity: 1;
            }
          }
      }
    }
    .download {
      display: none;
    }
  }
  .copyright {
    background-color: #1C2024;
    // background-color: #2e3139;
    font-size: 1.2rem;
    color: #999999;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem 0;
      span{
        a{
            color: rgba(255, 255, 255, .6);
            text-decoration: none;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #footer {
    margin-top: 4rem;
    color: #fff;
    background-color: $color;
    .contact-us{
      padding: 1rem 2rem;
      background-color: #1C2024;
      .title{
        font-size: 16px;
        font-weight: 600;
      }
    }
    .header {
      font-size: 1.8rem;
      padding: 3.3rem 0 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: none;
      .left {
        width: 100%;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          margin: 0 1rem;
        }
      }
      .name {
        display: none;
      }
    }
    .cont {
      padding: 0 0 2rem 0;
      display: flex;
      justify-content: center;
      align-content: center;
      .cont-lg {
        display: none;
      }
      .download {
        display: block;
        color: #fff;
        background-color: $color;
        border-color: #fff;
        border-radius: 26px;
      }
    }
    .copyright {
      font-size: 1.2rem;
      color: #999999;
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 1rem 2rem;
      }
    }
  }
}
</style>