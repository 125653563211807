<template>
  <div class="login">
    <a-modal 
      :visible="$store.getters.isShowLogin"
      :width="400" 
      :bodyStyle="{padding:'40px 24px'}" 
      :footer="false"
      @cancel="close"
      >
      <div class="modal-title">{{isCode ? $t('login.email') : $t('login.password')}}</div>
      <van-divider />
      <div class="modal-form">
        <van-form @submit="onLogin">
          <van-field class="modal-field" type="text" clearable v-model="login.account" :placeholder="$t('login.tips_1')" :rules="[{ required: true, message: $t('login.tips_1') }]"/>
          <van-field class="modal-field" v-show="!isCode" type="password" clearable v-model="login.password" :placeholder="$t('login.tips_3')" :rules="[{ required:  isCode ? false : true, message: $t('login.tips_3') }]"/>
          <van-field class="modal-field" v-show="isCode" v-model="login.captcha" clearable :placeholder="$t('login.tips_4')" :rules="[{ required: isCode ? true : false, message: $t('login.tips_4') }]">
            <template #button>
              <span class="colorBtn" v-show="!isStart" @click.stop="sendCode">{{$t('login.tips_5')}}</span>
              <van-count-down ref="countDown" v-show="isStart" @finish="finish" :time="60000" :auto-start="false" format="ss s" style="color:#2564F6;"/>
            </template>
          </van-field>
          <van-button color='#2564F6' type="primary" native-type="submit" block>{{$t('route.login')}}</van-button>
        </van-form>
        <div class="login-switch flex-end">
          <span @click="isCode = !isCode">{{ isCode ? $t('login.password') : $t('login.email')}} </span>
        </div>
        <div class="text">{{$t('login.text_1')}}
          <span class="colorBtn" @click="routerClick(1)">{{$t('login.treaty_1')}}</span>{{$t('login.text')}}
          <span class="colorBtn" @click="routerClick(0)">{{$t('login.treaty_2')}}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { CodeKey, SendCode, CodeLogin, PasswordLogin } from '@/api/api.js'
export default {
  data() {
    return {
      isCode:true,
      isStart:false,
      login:{
        account:'',
        captcha:'',
        password:'',
      },
    }
  },
  methods: {
    async sendCode(){
      if(!this.login.account) return this.$message.warning('请输入账号')
      const code = await CodeKey()
      const parms = {account:this.login.account,type:'email',key:code.data.key}
      const loading =  this.$message.loading('发送中..', 0);
      const result = await SendCode(parms)
      setTimeout(loading, 0);
      if (result.status !== 200 ) return
      this.$message.success(result.msg)
      this.isStart = true
      this.$refs.countDown.start();
    },
    finish() {
      this.isStart = false
      this.$refs.countDown.reset();
    },
    //登录
    async onLogin(){
      const apiMethod = this.isCode ? CodeLogin : PasswordLogin;
      const { account, captcha, password} = this.login
      const result = await apiMethod({ account,...(this.isCode ? { captcha } : { password }) });
      if (result.status !== 200 ) return
      localStorage.setItem('smart_token',result.data.token) 
      this.$message.success(result.msg)
      this.$store.commit('Login', true)
      setTimeout(()=>{
        this.close()
        location.reload()
      },2000)
    },
    close(){
      this.$store.commit('LoginShow', false)
      Object.assign(this.$data,this.$options.data());
      if(this.$route.meta.login){
        if(window.history.length > 2){
          this.$router.go(-1);
        }else{
          this.$router.push({ name: '/'}) 
        }
      }
    },
    routerClick(type){
      this.$store.commit('LoginShow', false)
      this.$router.push({ name: type ? 'Treaty' :'Privacy'});
    },
  },
}
</script>
<style lang="scss" scoped>

</style>