export default {
  route: {
    'home': '漫遊卡',
    'mine': '個人中心',
    'recharge': '充值',
    'enquiry':'使用查詢',
    'search': '搜索目的地 (如：香港)',
    'login': '登錄',
    'logout': '退出登錄',
    'sim_order':'購卡訂單',
    'flow_order':'充值訂單',
    'address':'收貨地址',
    'guide':'使用幫助',
    'user':'個人信息',
  },
  common:{
    'days':'天',
    'cancel':'取消',
    'ok':'確認',
    'all':'全部套餐',
    'hot':'熱門地區',
    'other':'其他套餐',
    'slogan':'讓信號一直在您身邊',
    'price_1':'價格',
    'price_2':'起',
    'tup-op_tips':'請輸入要充值的漫遊卡號',
    'title_1':'套餐規格：',
    'title_2':'套餐天數',
    'title_3':'套餐說明',
    'title_4':'購買數量',
    'title_5':'充值卡號',
    'title_6':'請選擇收貨方式',
    'title_7':'請選擇收貨地址',
    'title_8':'請添加收貨地址',
    'title_9':'請選擇配送快遞',
    'title_10':'請選擇您要充值的漫遊卡套餐',
    'btn_1':'立即購買',
    'btn_2':'立即充值',
    'btn_3':'添加新地址',
    'pay_1':'確認訂單',
    'pay_2':'訂單信息',
    'pay_3':'最晚使用日期：',
    'pay_4':'請選擇支付方式',
    'pay_5':'應付金額：',
    'pay_6':'確認付款',
    'pay_7':'訂單金額：',
    'pay_8':'訂單號：',
    'pay_9':'訂單提交成功，請您盡快完成付款！',
    'pay_10':'取消支付',
    'pay_11':'我已完成支付',
    'pay_12':'查詢訂單中，請稍候',
    'pay_13':'合計：',
    'pay_14':'郵寄',
    'pay_15':'自取',
    'search_1':'搜索關鍵字：',
    'search_2':'搜索結果',
    'search_3':'暫無此套餐',
    'search_4':'請輸入關鍵字',
  },
  footer:{
    'name':'優遊寶國際控股有限公司',
    'hk_name':'香港總部',
    'hk_addess':'香港九龍長沙灣大南西街1018號東方國際大廈11樓1103室',
    'am_name':'澳門分部',
    'am_addess':'澳門宋玉生廣場336-342號誠豐商業中心17樓F/G室',
    'sz_name':'深圳分部',
    'sz_addess':'深圳市南山區科偉路3號東方科技大廈2515室',
    'tw_name':'臺灣分部',
    'tw_addess':'臺北市文山區汀州路四段105巷28號1樓',
    'kf_title':'客服咨詢:',
    'work_title':'合作咨詢:',
    'link_title':'友情鏈接',
    'link_yyb':'優遊寶官網',
    'link_wyb':'萬遊寶商城',
    'link_esim':'TraveleSIM商城',
    'scan_app':'掃碼下載APP',
    'scan_wechat':'掃碼關註公眾號',
    'download':'下載TraveMate APP'
  },
  http: {
    'timeout':'服務器響應超時，請刷新當前頁',
    'fail': '數據獲取失敗',
    'server_fail':'連接服務器失敗',
    'err': '請求接口失敗',
    '400': '請求錯誤',
    '404': '請求錯誤,未找到該資源',
    '500':'服務器端出錯',
    '502':'網絡錯誤',
    '504':'網絡超時',
  },
  login: {
    'password':'賬號登錄',
    'email':'快速登錄',
    'reset':'輸入密碼不一致',
    'logout_title':'退出登錄',
    'logout_text':'確認退出登錄？',
    'logout_ok':'退出登錄',
    'title_1':'忘記密碼',
    'title_2':'註冊',
    'tips_1':'請輸入郵箱地址',
    'tips_2':'請再次確認密碼',
    'tips_3':'請輸入密碼',
    'tips_4':'請輸入驗證碼',
    'tips_5':'獲取驗證碼',
    'tips_6':'已有賬號，',
    'tips_7':'馬上登錄',
    'tips_8':'請輸入新的賬號密碼',
    'tips_9':'請再次確認新的賬號密碼',
    'tips_10':'返回登錄',
    'area':'請選擇區號',
    'treaty_1':'《用戶協議》',
    'treaty_2':'《隱私政策》',
    'text_1':'輸入郵箱地址，用於創建萬遊寶賬號和收取購買訂單電子收據。點擊「登錄」，即代表您同意我們的',
    'text_2':'為了您的賬號安全，請驗證郵箱',
    'text_3':'為了您的賬號安全，請驗證手機',
    'text_4':'輸入手機號碼/郵箱地址，註冊漫遊卡賬號。點擊「註冊」，即代表您同意我們的',
    'text':'和',
    'tab_1':'或手機',
    'tab_2':'或郵箱',
  },
  mine:{
    'tips':'*訂單包含漫遊卡和流量套餐，如需了解更多套餐詳細信息，請下載',
    'app':'萬遊寶App',
    'tab_1':'全部',
    'tab_2':'待付款',
    'tab_3':'待發貨',
    'tab_4':'待收貨',
    'tab_5':'待自取',
    'tab_6':'已完成',
    'tab_7':'已付款',
    'tab_8':'已取消',
    'time':'剩余付款時間：',
    'user_1':'賬號：',
    'user_2':'登錄密碼',
    'user_3':'已設置',
    'user_4':'修改密碼',
    'user_5':'綁定郵箱',
    'user_6':'已綁定',
    'user_7':'未綁定',
    'user_8':'修改',
    'user_9':'綁定',
    'user_10':'綁定手機',
    'user_11':'請輸入舊密碼',
    'user_12':'請輸入新密碼',
    'user_13':'請輸入郵箱地址',
    'user_14':'請輸入手機號碼',
    'order_1':'地區套餐',
    'order_2':'下單時間',
    'order_3':'實付款',
    'order_4':'訂單狀態',
    'order_5':'支付方式',
    'order_6':'操作',
    'order_7':'訂單編號：',
    'order_8':'立即支付',
    'order_9':'實付：',
    'order_10':'暫無訂單',
    'order_11':'收貨方式',
    'order_12':'物流詳情',
    'order_13':'確認收貨',
    'order_14':'快遞單號：',
    'order_15':'快遞公司：',
    'order_16':'物流信息：',
    'order_17':'物流詳情',
    'order_18':'一鍵復製',
    'copy_1':'復製成功',
    'copy_2':'復製失敗',
    'flow_1':'請在{msg}前使用',
    'flow_2':'於{msg}取消',
    'add_1':'收件人',
    'add_2':'地區',
    'add_3':'請選擇所在地區',
    'add_4':'設為默認地址',
    'add_5':'詳細地址',
    'add_6':'請輸入詳細地址',
    'add_7':'郵政編碼',
    'add_8':'請輸入郵政編碼',
    'add_9':'編輯地址',
    'add_10':'請選擇',
    'add_11':'地址',
    'add_12':'默認地址',
    'add_13':'姓名：',
    'add_14':'電話：',
    'add_15':'郵編：',
    'add_16':'刪除',
    'add_17':'編輯',
    'add_18':'默認',
    'add_19':'編輯地址',
  },
  user:{
    'privacy_title':'隐私政策',
    'privacy':`
    t_0:'万游宝遵守适用的数据保护及隐私法律和规例（“适用法律”）。在适用法律允许的最大范围内，及受用户在适用法律要求下的有效同意所规限，用户在使用万游宝软件、服务、网站或任何其他提供万游宝服务的第三方平台，即表示同意及接受本隐私政策。我们可能不时更新本隐私政策以反映我们的软件或商品的改变和用户意见，或适用法律的改变。如本隐私政策或对于我们將如何使用用户的个人资料之方式有重大改变时，我们会在实施该等改变之前以明显的方式刊登有关通知或直接向用户发出通知。我们鼓励用户定期细阅读本隐私政策，以得知我们正如何收集，使用及保护用户的资料。',
		t_1:{
			title:'1. 我们收集的个人信息',
			p1:'我们可能会不时向用户收集个人资料或其他信息，以便向用户提供我们的产品或服务。我们所收集的信息包括但不限于：',
			p2:'(a)用户在注册时所提供的资料，或是稍后显示在万游宝软件用户端、万游宝线上搜寻目录以及在万游宝软件的个人中心页面的万游宝个人资料。其中可能包括用户的万游宝头像、手机号码等，以及用户同意提供的所有其他资料； ',
			p3:'(b)身份识别资料（如姓名、地址、电话号码、手机号码）；',
			p4:'(c)电子识别资料（如IP地址、cookies）； ',
			p5:'(d)有关用户使用我们的软件、服务、产品或网页互动的资讯（包括电脑、用户端资讯、错误报告、页面浏览统计资料、浏览器类型和使用统计资料)；',
			p6:'(e)流量数据（为传送通讯或计费而处理的数据）；',
			p7:'(f)付款详情，包括收件人信息及支付方式；',
			p8:'(g)联系人详情，包括联系人姓名、电话号码等。 ',
			p9:'此外，用户的线上时段资料可能会间接地以统计和匿名的方式被收集。 ',
			p10:'如用户未满18岁时，用户需在家长或监护人的同意和监督下提供个人资料。',
			p11:'本隐私政策不适用于通过下列方式所收集的数据：通过万游宝服务而接入第三方服务（包括任何第三方网站）；或通过在万游宝宣传服务的其他公司和机构所收集的资料。',
		},
		t_2:{
			title:'2. 用户个人信息的使用',
			p1:'我们可能会不时向用户收集个人资料或其他信息，以便向用户提供我们的产品或服务。我们所收集的信息包括但不限于：',
			p2:'(a) 接受、处理或传送经由我们的网站产生的订单，开立发票给用户或通知用户订单状态；',
			p3:'(b) 为用户提供其他服务（如我们收集资讯时所述）；',
			p4:'(c) 为用户提供客户紧急救援及其它疑难排解服务； ',
			p5:'(d) 比较资讯的准确性；',
			p6:'(e) 通知用户关于服务更新与故障的资讯；',
			p7:'(f) 解决争议；',
			p8:'(g) 验证用户的身份； ',
			p9:'(h) 改善我们的软体、服务或网站；',
			p10:'(i) 筹划与执行我们的营销或推广活动、优惠和类似活动；',
			p11:'(j) 通过广告支持，免费提供某些软件、功能及网站； ',
			p12:'(k) 针对故障和服务问题与用户联络，传送相关讯息； ',
			p13:'(l) 以电子邮件、软件或短信进行通知，使用户知悉关于使用万游宝软件、产品或网站的最终求偿资讯，包括但不限于侵犯第三方权利的侵权求偿； ',
			p14:'(m) 使用短信或万游宝消息去通知用户万游宝软件、服务或网站的最新信息，及告知用户我们的相关推广。',
		},
		t_3:{
			title:'3. 用户个人信息的披露及转移',
			p1:'除了以下所述之外，在未获得用户的有效及明确许可下，万游宝不会销售、出租、交易或转让任何个人流量数据或通讯内容，除非万游宝在适用法律或管辖机构的命令下有义务作出上述行为。',
			p2:'万游宝可能披露个人信息以回应法律规定，行使我们的法律权利或于索偿中抗辩，以保护万游宝的利益，打击诈欺行为及执行我们的政策或保护用户的合法权利、财产或安全。',
			p3:'万游宝、万游宝的合作伙伴或促进用户的通讯运营商或公司可提供个人资料、通讯内容或流量资料給适当司法、执法或合法地要求该等信息的政府机构。受用户的有效及明确同意所限，万游宝可能将用户的个人资料提供给供应商或万游宝的关联公司。',
		},
		t_4:{
			title:'4. 用户的个人信息安全及保存',
			p1:'我们会在用户是我们客户期间或用户已不再使用我们的服务之后储存用户的个人信息，但此仅为有必要的或者因应法律要求。我们将严格执行相关法律下的适用义务和例外规定，采取适当的组织性和技术性措施保护向其提供或所收集的个人资料和流量资料。用户的个人资料和流量资料仅供经许可员工或第三方存取，并且只会在必要时才保存至达到履行其收集的原来目的或直接相关的目的时，个人资料和流量资料被保存以符合任何适用法律或合同义务除外。'
		},
		t_5:{
			title:'5. 查阅或改正数据',
			p1:'用户有权查阅用户的个人信息，并在相关的情况下加以改正。',
			p2:'除非法律要求保留或因为合法的商业目的，我们将尽合理努力满足删除个人资讯的要求。',
			p3:'根据现行法律，我们保留对处理任何查阅资料请求而收取合理费用的权利。',
		},
		t_6:{
			title:'6. 手机通知服务',
			p1:'当用户的手机使用万游宝时，我们可能会在用户没有执行或没有使用万游宝应用的情况下，利用该手机的通知服务让用户得知资讯。这些服务项目可能由第三方提供。例如，传送到Apple装置的讯息会使用Apple的紧急通知服务。',
			p2:'通知服务可能收到关于来电者、讯息发送者和讯息内容的资讯以提供服务，并可能根据服务提供者的条款和细则及隐私权原则来使用这些资讯。 (Apple为AppleInc.在美国及其他国家的注册商标。)',
		},
		t_7:{
			title:'7. 第三方服务',
			p1:'7.1 为了实现【 第三方社交平台登录 】功能，我们使用了 MobTech 的【 ShareSDK 第三方登录 】产品。',
			p2:'此产品的隐私策略条款：',
			p3:'7.2 我们的产品集成【友盟+SDK】，【友盟+SDK】需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。',
			p4:'此产品的隐私权政策链接：'
		},
		t_8:{
			title:'8. 联系我们',
			p1:'如用户对本隐私政策有任何查询，请在万游宝应用内联系我们客服。',
		}
    `,
  }
}