import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginShow:false,
    login:false
  },
  getters: {
    isShowLogin(state) {
      // if(state.loginShow){
      //   document.body.style.overflow = 'hidden';
      // }else{
      //   document.body.style.overflow = '';
      // }
      return state.loginShow
    },
  },
  mutations: {
    LoginShow(state,show){
      state.loginShow = show
    },
    Login(state,type){
      state.login = type
    }
  },
  actions: {
  },
  modules: {
  }
})

